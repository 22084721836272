import React, {useState, useRef} from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";

import "../style/index.scss"

function FormControl({label, onChange, controlId, children, ...rest}) {
  return (<Form.Group controlId={controlId}>
    <Form.Label>{label}</Form.Label>
    <Form.Control onChange={(e) => {
      return onChange(e.currentTarget.value);
    }} {...rest}>
      {children}
    </Form.Control>
  </Form.Group>)
}

export default function ContactForm(props) {
  const formRef = useRef();
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");

  return (<>
    <form id="contact-form" action="/contact-form/?form-submitted=true">
      <Container fluid>
        <Row className="pb-2">
          <Col>
            {"Enter details below and one of our Sales team will be in touch"}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormControl controlId="contactUs.firstName" label={"First Name"} value={firstName} onChange={setFirstName} />
          </Col>
          <Col>
            <FormControl controlId="contactUs.lastName" label={"Last Name"} value={lastName} onChange={setLastName} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormControl type="email" controlId="contactUs.emailAddress" label={"Email Address"} value={emailAddress} onChange={setEmailAddress} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormControl controlId="contactUs.companyName" label={"Company Name"} value={companyName} onChange={setCompanyName} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormControl type="tel" controlId="contactUs.phone" label={"Phone"} value={phone} onChange={setPhone} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormControl as="textarea" rows="3" controlId="contactUs.message" label={"Message"} value={message} onChange={setMessage} />
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto" xs="auto">
            <Button variant="green" type="submit">
              {"Contact Sales"}
            </Button>
          </Col>
        </Row>
      </Container>
    </form>
    <script type="text/javascript" dangerouslySetInnerHTML={{
      __html: `(function(o){var b="https://turboeagle.co/anywhere/",t="e844d62a6c7c47bb9d499c12b95304b9d2cd8253d06143d08ada231250441cee",a=window.AutopilotAnywhere={_runQueue:[],run:function(){this._runQueue.push(arguments);}},c=encodeURIComponent,s="SCRIPT",d=document,l=d.getElementsByTagName(s)[0],p="t="+c(d.title||"")+"&u="+c(d.location.href||"")+"&r="+c(d.referrer||""),j="text/javascript",z,y;if(!window.Autopilot) window.Autopilot=a;if(o.app) p="devmode=true&"+p;z=function(src,asy){var e=d.createElement(s);e.src=src;e.type=j;e.async=asy;l.parentNode.insertBefore(e,l);};y=function(){z(b+t+'?'+p,true);};if(window.attachEvent){window.attachEvent("onload",y);}else{window.addEventListener("load",y,false);}})({});`
    }} />
  </>);
}

